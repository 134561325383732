import { Injectable } from '@angular/core';
import {ConfirmationService} from "primeng/api";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(public confirmationService: ConfirmationService) {}

    /**
     * Metodo che mostra una dialog di conferma
     */
    show(): Observable<boolean> {
        return new Observable<boolean>((observer) => {
            this.confirmationService.confirm({
                message: 'Sei sicuro di voler procedere?',
                header: 'Conferma',
                acceptLabel: 'Conferma',
                rejectLabel: 'Annulla',
                acceptButtonStyleClass: 'p-button-danger',
                rejectButtonStyleClass: 'p-button-outlined',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    // Quando viene cliccato "Conferma", emetti true tramite l'observable
                    observer.next(true);
                    observer.complete(); // Completa l'observable
                },
                reject: () => {
                    // Quando viene cliccato "Annulla", emetti false tramite l'observable
                    observer.next(false);
                    observer.complete(); // Completa l'observable
                }
            });
        });
    }

}
