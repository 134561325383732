<div [ngClass]="containerClass" class="layout-wrapper">
    <app-topbar></app-topbar>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="mb-4 mt-0 mx-auto main">
            <p-toast></p-toast>
            <router-outlet></router-outlet>
            <app-videovisit></app-videovisit>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="layout-mask"></div>
</div>
