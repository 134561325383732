<ng-container *ngIf="events && eventSummaryResponse">
    <div #eventBell>
        <button class="relative m-0 p-link layout-topbar-button" (click)="overlayPanel.toggle($event)">
            <div *ngIf="count > 0" class="badge"
                 [style.background-color]="events.content[0].level | levelBgColor"
                 [style.color]="events.content[0].level | levelColor">
                {{count}}
            </div>
            <app-icon icon="notifications"></app-icon>
            <span>Eventi</span>
        </button>

        <p-overlayPanel #overlayPanel styleClass="eventOverlayPanel" [appendTo]="eventBell" [style]="{width: '600px'}">
            <app-event-summary [summary]="eventSummaryResponse" [expanded]="true" [condensed]="true"></app-event-summary>

            <p *ngIf="!events || events.content.length === 0; else listEvent" class="no-data">
                Nessun evento registrato
            </p>

            <ng-template #listEvent>
                <ul class="m-0 p-0" style="list-style: none">
                    <li *ngFor="let event of events.content" (click)="goToDetail(event)"
                        class="flex pr-2 border-bottom-1 border-gray-200 cursor-pointer hover:bg-gray-200">
                        <div class="level-border"
                             [style.border-left-color]="event.level | levelBgColor"
                             [style.color]="event.level | levelBgColor">
                            <i [class]="event.level | levelIcon" [pTooltip]="event.level | levelLabel"></i>
                        </div>
                        <div class="flex-grow-1">
                            <p class="m-0 text-bold">{{ event.subject.firstname }} {{ event.subject.lastname }}</p>
                            <div class="flex align-items-center">
                                <em class="material-symbols">{{ event.type | eventIcon }}</em>
                                <span class="text-ellipsis flex-grow-1">{{ event.type | eventType }}</span>
                            </div>
                            <small>
                                Aperto il {{ event.createdDate | date:'shortDate':'it-IT' }}
                                alle {{ event.createdDate | date:'shortTime':'it-IT' }}
                            </small>
                        </div>
                        <div class="py-2 flex svc-flex-column justify-content-center align-items-center text-center">
                            <p-badge *ngIf="!event.closed && !event.manager" severity="danger"
                                     [value]="'Aperto'"></p-badge>
                            <p-badge *ngIf="!event.closed && event.manager" severity="warning"
                                     [value]="'In corso'"></p-badge>
                            <p-badge *ngIf="event.closed" severity="success" [value]="'Concluso'"></p-badge>
                            <p *ngIf="event.manager">
                                Gestito da: <strong>{{ event.manager.firstname }} {{ event.manager.lastname }}</strong>
                            </p>
                        </div>
                    </li>
                </ul>
            </ng-template>
            <div class="mt-3 flex justify-content-center">
                <p-button [routerLink]="['/app/events']"
                          size="small"
                          styleClass="p-button-outline"
                          label="Vedi tutti" icon="pi pi-search">
                </p-button>
            </div>
        </p-overlayPanel>

    </div>
</ng-container>
