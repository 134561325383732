<div class="layout-topbar">
    <button #menubutton (click)="layoutService.onMenuToggle()"
            class="p-link layout-menu-button layout-topbar-button lg:hidden">
        <app-icon icon="menu"></app-icon>
    </button>

    <a class="layout-topbar-logo" routerLink="/">
        <img alt="{{organization?.name}}" src="{{organization?.logoUrl}}">
    </a>

    <div class="layout-topbar-spacer"></div>

    <button #topbarmenubutton (click)="layoutService.showProfileSidebar()"
            class="p-link layout-topbar-menu-button layout-topbar-button">
        <app-icon icon="more_vert"></app-icon>
    </button>

    <div class="layout-topbar-universal-searchbar">
        <app-universal-searchbar></app-universal-searchbar>
    </div>

    <div class="layout-topbar-events">
        <app-topbar-events></app-topbar-events>
    </div>

    <div #topbarmenu *ngIf="profile"
         [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}"
         class="layout-topbar-menu">
        <div class="loggedUser">
            <p *ngIf="profile" class="profile">{{profile.firstname}} {{profile.lastname}}</p>
            <p *ngIf="profile" class="username">{{profile.username}}</p>
            <p *ngIf="organization" class="organization">
                <strong>{{organization.name}}</strong>
            </p>
        </div>
        <button (click)="openLink('https://doc.whespdev.com')" class="m-0 p-link layout-topbar-button">
            <app-icon icon="help"></app-icon>
            <span>Help</span>
        </button>
        <button (click)="doLogout()" class="m-0 p-link layout-topbar-button">
            <app-icon icon="logout"></app-icon>
            <span>Esci</span>
        </button>
    </div>
</div>

<p-confirmDialog header="Conferma operazione" icon="fa fa-exclamation-triangle"></p-confirmDialog>
