import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {EMPTY, mergeMap, Observable, take} from 'rxjs';
import {ConfirmDialogService} from "../services/confirm-dialog.service";
import {CONFIRM} from "./http.context";

@Injectable()
export class DeleteInterceptor implements HttpInterceptor {

    constructor(private modalService: ConfirmDialogService) {
    }

    /**
     * L'interceptor gestisce le richieste DELETE mostrando una dialog di conferma. Se l'utente clicca si, fa proseguire
     * la richiesta altrimenti la stoppa.
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.method === 'DELETE') {
            // Apri la dialog e attendi la conferma
            return this.showConfirm(request, next);
        }
        // se il metodo è PUT e ha il context
        else if (request.method === 'PUT' && request.context.get(CONFIRM)) {
            return this.showConfirm(request, next);
        }
        // Se il metodo non è DELETE, passa la richiesta all'interceptor successivo
        else {
            return next.handle(request);
        }
    }

    private showConfirm(request: HttpRequest<unknown>, next: HttpHandler) {
        return this.modalService.show().pipe(
            take(1), // Assicurati di prendere solo un valore (conferma o rifiuto)
            mergeMap((confirmed: boolean) => {
                if (confirmed) {
                    // Se confermato, procedi con la chiamata DELETE
                    return next.handle(request);
                } else {
                    // Altrimenti, restituisci un observable vuoto o gestisci l'annullamento
                    // Puoi usare 'of(null)' per un observable vuoto
                    // o generare un errore per gestire l'annullamento.
                    // Esempio con observable vuoto:
                    return EMPTY;
                }
            })
        );
    }
}
