import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {finalize, Observable, Subject} from 'rxjs';
import {SpinnerService} from "../service/spinner.service";
import {NO_SPINNER} from "../../../interceptors/http.context";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    private exclusions = [
        'measures/statistics',
        'core/goals',
        'core/thresholds',
        'measures',
        'draft'
    ]

    requestCounter = 0;

    constructor(
        private loadingService: SpinnerService
    ) {

    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.exclusions.some((item) => request.url.includes(item)) || (request.context.get(NO_SPINNER))) {
            return next.handle(request.clone())
        }

        this.requestCounter++
        this.loadingService.setLoading(true);
        return next
            .handle(request.clone())
            .pipe(
                finalize(() => {
                    this.requestCounter--
                    if (this.requestCounter == 0)
                        this.loadingService.setLoading(false);
                })
            );
    }

}
