import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {AppLayoutModule} from './layout/app.layout.module';
import {MessagesModule} from 'primeng/messages';
import {ConfirmationService, MessageService} from 'primeng/api';
import {BaseUrlInterceptor} from './interceptors/base-url.interceptor';

// Locales
import {CommonModule, registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
// Full calendar
import {FullCalendarModule} from '@fullcalendar/angular';
import {InitService} from './services/init.service';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {SpinnerComponent} from "./core/spinner/component/spinner.component";
import {SpinnerInterceptor} from "./core/spinner/interceptor/spinner.interceptor";
import {DeleteInterceptor} from "./interceptors/delete.interceptor";

registerLocaleData(localeIt);

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        AppRoutingModule,
        AppLayoutModule,
        MessagesModule,
        FullCalendarModule,
        SpinnerComponent], providers: [
        MessageService,
        ConfirmationService,
        { provide: LOCALE_ID, useValue: 'it-IT' },
        {
            provide: APP_INITIALIZER,
            useFactory: (initService: InitService) => {
                return () => initService.init();
            },
            deps: [InitService],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DeleteInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
