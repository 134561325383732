import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {TypologyService} from './typology.service';
import {Observable, of, switchMap, zip} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {PrimeNGConfig} from 'primeng/api';
import moment from "moment";
import { HttpClient } from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class InitService {

    constructor(
        private authService: AuthService,
        private typologyService: TypologyService,
        private primeNGConfig: PrimeNGConfig,
        private httpClient: HttpClient
    ) {
    }

    init(): Observable<any> {


        moment.locale(window.navigator.language)
        this.primeNGConfig.setTranslation({
            "dayNames": ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
            "dayNamesShort": ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            "dayNamesMin": ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
            "monthNames": ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            "monthNamesShort": ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
            "today": "Oggi",
            "firstDayOfWeek": 1,
        })

        return this.fetchEnvironmentConfig()
            .pipe(
                switchMap(() => {
                    return zip([
                        this.authService
                            .init()
                            .pipe(
                                switchMap(() => this.typologyService.init()),
                                catchError((err) => {
                                    return of(err);
                                })
                            )
                    ])
                })
            )
    }

    fetchEnvironmentConfig() {
        return this.httpClient.get(`/config/environment.json`).pipe(
            tap((value: any) => {
                Object.keys(value).forEach(key => {
                    (environment as any)[key] = value[key];
                })
            }))
    }

}
