import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EventsService} from "../../services/events.service";
import {EventSummaryResponse} from "../../messages/events/event-summary-response";
import {WebsocketService} from "../../services/websocket.service";
import {StompSubscription} from "@stomp/stompjs";
import {AuthService} from "../../services/auth.service";
import {MessageService} from "primeng/api";
import {PaginatedResponseMessage} from "../../messages/common/paginated-response-message";
import {Event} from "../../models/event.models";
import {Router} from "@angular/router";
import {OverlayPanel} from "primeng/overlaypanel";
import {SearchEventsRequestMessage} from "../../messages/events/search-events-request-message";
import {Level} from "../../models/typology.models";

@Component({
    selector: 'app-topbar-events',
    templateUrl: './app.topbar.events.component.html',
    styleUrls: ['./app.topbar.events.component.scss']
})
export class AppTopbarEventsComponent implements OnInit, OnDestroy {
    @ViewChild('overlayPanel') overlayPanel?: OverlayPanel;
    eventSummaryResponse?: EventSummaryResponse;
    events?: PaginatedResponseMessage<Event>;
    searchFilter?: SearchEventsRequestMessage;

    private organizationEventsStompSubscription: StompSubscription | undefined;

    constructor(
        private router: Router,
        private authService: AuthService,
        private eventsService: EventsService,
        private websocketService: WebsocketService,
        private messageService: MessageService,
    ) {
    }

    get count(): number {
        return this.events?.totalElements || 0;
    }

    ngOnInit() {
        this.router.events.subscribe(() => {
            this.overlayPanel?.hide();
        })
        this.eventsService.getTopBarSearchFilter(this.authService.organizationId!)
            .subscribe(searchFilter => {
                this.searchFilter = searchFilter;
                this.fetch();
                this.organizationEventsStompSubscription = this.websocketService.subscribeToTopicWithDebounce(
                    `/topic/org/${this.authService.organizationId}/events`,
                    () => {
                        this.messageService.add({
                            severity: 'info',
                            summary: 'Aggiornamento eventi',
                        });
                        this.fetch();
                    },
                    1000
                );
            })
    }

    ngOnDestroy(): void {
        if (this.organizationEventsStompSubscription) {
            this.organizationEventsStompSubscription.unsubscribe()
        }
    }

    goToDetail(event: Event) {
        this.router.navigateByUrl(`app/users/${event.subject.sub}/events/${event.id}`);
    }

    private fetch(): void {
        this.eventsService.summary(true).subscribe(value => this.eventSummaryResponse = value);
        this.eventsService.search({page: 0, sortBy: 'level', sortDirection: "DESC", ...this.searchFilter}, true)
            .subscribe((events) => this.events = events)
    }
}
