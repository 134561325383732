import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {BehaviorSubject, filter, first, Observable, switchMap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {NO_ACCESS_TOKEN, REFRESH_TOKEN, STRAPI} from './http.context';

export const retryCount = 3;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = new BehaviorSubject(false);

    constructor(private authService: AuthService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.context.get(NO_ACCESS_TOKEN) || request.context.get(STRAPI)) {
            return next.handle(request);
        }
        if (this.isRefreshing.getValue()) {
            console.log('wait for refresh', request.url);
            return this.isRefreshing.pipe(
                filter(v => !v),
                first(),
                switchMap(() => this.handleClone(request, next))
            );
        }
        const expireTimestamp = this.authService.expireTimestamp!;
        if (new Date().getTime() > (expireTimestamp - 2000)) {
            return this.tryRefresh(request, next);
        }
        return this.handleClone(request, next);
    }

    private handleClone(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(
            request.clone({
                headers: request.headers.append('Authorization', `Bearer ${this.authService.accessToken}`),
                context: request.context.set(REFRESH_TOKEN, false),
            })
        )
    }

    private tryRefresh(request: HttpRequest<unknown>, next: HttpHandler, nTry: number = 1): Observable<HttpEvent<unknown>> {
        if (request.context.get(REFRESH_TOKEN) && nTry > retryCount) {
            console.error(`Numero massimi di tentativi raggiunto`);
            this.authService.doLogout();
            return throwError(() => new Error('Numero massimi di tentativi raggiunto'));
        }
        this.isRefreshing.next(true);
        console.log(`try refresh token `, nTry);
        return this.authService.doRefreshToken().pipe(
            switchMap(() => {
                console.log(`refresh token success`);
                this.isRefreshing.next(false);
                return this.handleClone(request, next);
            }),
            catchError((err) => {
                this.isRefreshing.next(false);
                if (request.context.get(REFRESH_TOKEN)) {
                    return this.tryRefresh(request, next, ++nTry);
                }
                return throwError(() => err);
            })
        );
    }
}
