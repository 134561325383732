import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {UnauthenticatedGuard} from './guards/unauthenticated.guard';
import {AuthenticatedGuard} from './guards/authenticated.guard';
import {AppLayoutComponent} from './layout/app.layout.component';
import {AppErrorComponent} from './layout/app.error.component';
import {AppRouteReuseStrategy} from "./strategies/app.route-reuse.strategy";

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [UnauthenticatedGuard],
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'error',
        canActivate: [AuthenticatedGuard],
        component: AppErrorComponent
    },
    {
        path: 'app',
        canActivate: [AuthenticatedGuard],
        component: AppLayoutComponent,
        children: [
            {
                path: 'home',
                loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'organization',
                loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'groups',
                loadChildren: () => import('./modules/groups/groups.module').then(m => m.GroupsModule)
            },
            {
                path: 'monitoring-plan-template',
                loadChildren: () => import('./modules/monitoring-plan-template/monitoring-plan-template.module').then(m => m.MonitoringPlanTemplateModule)
            },
            {
                path: 'protocol-template',
                data: {
                    type: 'USER_JOURNEY'
                },
                loadChildren: () => import('./modules/protocol-template/protocol-template.module').then(m => m.ProtocolTemplateModule)
            },
            {
                path: 'case-template',
                data: {
                  type: 'CASE'
                },
                loadChildren: () => import('./modules/protocol-template/protocol-template.module').then(m => m.ProtocolTemplateModule),
            },
            {
                path: 'workflow-designer',
                loadChildren: () => import('./modules/workflow-designer/workflow-designer.module').then(m => m.WorkflowDesignerModule)
            },
            {
                path: 'workflows',
                loadChildren: () => import('./modules/protocol-template/protocol-template.module').then(m => m.ProtocolTemplateModule)
            },
            {
                path: 'calendar',
                loadChildren: () => import('./modules/calendar/calendar.module').then(m => m.CalendarModule)
            },
            {
                path: 'survey',
                loadChildren: () => import('./modules/survey/survey.module').then(m => m.SurveyModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./modules/metabase/metabase.module').then(m => m.MetabaseModule)
            },
            {
                path: 'events',
                loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
            },
        ]
    },
    {path: '**', redirectTo: 'app/home', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
    })],
    providers: [
        { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy }
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
