import {Component, ElementRef, ViewChild} from '@angular/core';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {LayoutService} from "./service/app.layout.service";
import {Profile} from '../models/profile.models';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {Organization} from '../models/organizations.models';
import {OrganizationsService} from '../services/organizations.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    profile?: Profile;

    organization?: Organization;

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private router: Router,
        private organizationsService: OrganizationsService,
        private confirmationService: ConfirmationService
    ) {
    }

    ngOnInit() {
        this.profile = this.authService.profile
        this.organizationsService.detail(this.authService.organizationId!)
            .subscribe(organization => {
                if (!organization.logoUrl) {
                    organization.logoUrl = 'assets/logo-alt.svg';
                }
                this.organization = organization

            })
    }

    doLogout(): void {
        this.confirmationService.confirm({
            message: 'Sei sicuro di voler effettuare il logout?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            acceptButtonStyleClass: 'p-button-primary' ,
            rejectButtonStyleClass: 'p-button-outlined',
            accept: () => {
                this.authService.doLogout();
                this.router.navigateByUrl('/auth/login')
            }
        });
    }

    openLink(link: string) {
        window.open(link, '_blank');
    }
}
