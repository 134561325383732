import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {STRAPI} from './http.context';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let baseUrl = "";
        if(environment.baseUrl && !request.url.startsWith("http")) {
            baseUrl = environment.baseUrl;
            if (request.context.get(STRAPI)) {
                baseUrl = environment.strapiApiUrl
            }
        }
        return next.handle(
            request.clone({url: `${baseUrl}${request.url}`})
        );
    }
}
