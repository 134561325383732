import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router, Scroll} from '@angular/router';
import {MenuService} from '../services/menu.service';
import {AuthService} from '../services/auth.service';
import {collectAllParams} from '../utils/utils';
import {MenuItem, MenuItemType} from '../models/menu.models';
import {Profile} from '../models/profile.models';
import {UsersService} from "../services/users.service";
import {User} from "../models/users.models";
import {filter} from "rxjs";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    items: MenuItem[] = [];
    userItems: MenuItem[] = [];
    itemTypes = MenuItemType;
    profile: Profile | undefined;
    showUserMenu = false;
    user?: User;
    private _userItems: MenuItem[] = [];

    constructor(
        private menuService: MenuService,
        private authService: AuthService,
        private router: Router,
        private usersService: UsersService,
    ) {
    }

    ngOnInit() {
        this.menuService.sideMenu.subscribe(items => this.items = this.adjust(items))
        this.menuService.userMenu.subscribe(items => {
            this._userItems = items;
            this.userItems = this.adjust(items)
        })

        this.authService.getProfile()
            .subscribe(profile => {
                this.profile = profile
            })

        this.router.events.subscribe((event) => {
            if (event instanceof Scroll) {
                if (new RegExp(/^\/app\/users\/[^\s]+$/).test(event.routerEvent.url)) {
                    this.userItems = this.adjust(this._userItems)
                    this.showUserMenu = true;
                } else {
                    this.showUserMenu = false;
                }
            }
        })

        this.usersService.selectedUser$
            .pipe(
                filter(user => !!user),
                filter(user => user!.sub !== this.user?.sub)
            )
            .subscribe(user => this.user = user)
    }

    isVisible(item: MenuItem) {

        if (!item.restricted_to || item.restricted_to.length === 0) {
            return true
        }

        const restrictedRoles = item.restricted_to.map(r => r.role)
        return this.profile?.roles.some(r => restrictedRoles.indexOf(r) >= 0)
    }

    private adjust(items: MenuItem[]): MenuItem[] {
        const params = collectAllParams(this.router.routerState.root, {});
        return items
            .map(item => {
                const newItem = {...item}
                if (!item.options) {
                    newItem.options = {exact: false};
                }
                for (const k in params) {
                    newItem.path = newItem.path?.replace(`{${k}}`, params[k])
                }
                newItem.path = newItem.path?.replace(`{organizationId}`, `${this.authService.organizationId!}`)
                newItem.path = newItem.path?.replace(`{sub}`, this.authService.profile!.sub)
                return newItem;
            })
    }

}
