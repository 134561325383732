<div *ngIf="profile" class="flex justify-content-center">
    <ul class="layout-menu" [class.compressed]="showUserMenu">
        <li>
            <ul class="layout-menu">
                <ng-container *ngFor="let item of items; let i = index;">
                    <li *ngIf="isVisible(item)">
                        <ng-container [ngSwitch]="item.type">
                            <div *ngSwitchCase="itemTypes.DIVIDER">
                                <div *ngIf="item.label" class="divider-label">
                                    {{item.label}}
                                </div>
                                <div class="divider"></div>
                            </div>
                            <a *ngSwitchCase="itemTypes.EXTERNAL" id="menu-item-{{item.code}}" class="p-element"
                               routerLinkActive="active-route"
                               [href]="item.path" target="_blank"
                               [pTooltip]="showUserMenu ? item.label : undefined">
                                <em class="material-symbols layout-menuitem-icon">{{item.icon}}</em>
                                <span class="layout-menuitem-text">{{item.label}}</span>
                            </a>
                            <a *ngSwitchDefault id="menu-item-{{item.code}}" class="p-element"
                               routerLinkActive="active-route"
                               [routerLink]="item.path" [routerLinkActiveOptions]="item.options"
                               [queryParams]="item.queryParams"
                               [pTooltip]="showUserMenu ? item.label : undefined"
                            >
                                <em class="material-symbols layout-menuitem-icon">{{item.icon}}</em>
                                <span class="layout-menuitem-text">{{item.label}}</span>
                            </a>
                        </ng-container>
                    </li>
                </ng-container>

            </ul>
        </li>
    </ul>
    <div *ngIf="showUserMenu">
        <ng-container *ngIf="user">
            <div class="mx-3 mt-3 flex gap-2 align-items-center">
                <div class="flex-grow-1">
                    <h4 routerLink="/app/users/{{user.sub}}/dashboard" class="cursor-pointer text-bold">
                        {{user.firstname}} {{user.lastname}}
                    </h4>
                </div>
                <p-avatar image="assets/avatar.jpeg" shape="circle"></p-avatar>
            </div>

            <div class="mx-3 flex gap-2 align-items-center">
                <small class="text-gray-500">ID: {{user.id}}</small>
            </div>
            <div class="mx-3 mt-2 mb-2 flex gap-2 justify-content-center">
                <app-upcoming-videocall [user]="user" [label]="'Chiama'"></app-upcoming-videocall>
            </div>
        </ng-container>
        <ul class="layout-menu user-menu">
            <li>
                <ul class="layout-menu">
                    <li>
                        <div class="divider"></div>
                    </li>
                    <ng-container *ngFor="let item of userItems; let i = index;">
                        <li *ngIf="isVisible(item)">
                            <ng-container [ngSwitch]="item.type">
                                <div *ngSwitchCase="itemTypes.DIVIDER">
                                    <div *ngIf="item.label" class="divider-label">
                                        {{item.label}}
                                    </div>
                                    <div class="divider"></div>
                                </div>
                                <a *ngSwitchCase="itemTypes.EXTERNAL" id="menu-item-{{item.code}}" class="p-element"
                                   routerLinkActive="active-route"
                                   [href]="item.path" target="_blank">
                                    <span class="layout-menuitem-text">{{item.label}}</span>
                                </a>
                                <a *ngSwitchDefault id="menu-item-{{item.code}}" class="p-element"
                                   routerLinkActive="active-route"
                                   [routerLink]="item.path" [routerLinkActiveOptions]="item.options"
                                   [queryParams]="item.queryParams">
                                    <span class="layout-menuitem-text">{{item.label}}</span>
                                </a>
                            </ng-container>
                        </li>
                    </ng-container>

                </ul>
            </li>
        </ul>
    </div>
</div>
