export enum PageID {
    USERS = 'HOME',
    USER = 'USER',
    GROUPS = 'CALENDAR',
    GROUP = 'NOTIFICATION',
    ORGANIZATIONS = 'ORGANIZATIONS',
    ORGANIZATION = 'ORGANIZATION',
    CALENDAR = 'ORGANIZATION',
    NOTIFICATION = 'ORGANIZATION',
    PROFILE = 'ORGANIZATION',
}

export enum IDMenu {
    HOME = 'HOME',
    USER = 'USER',
    CALENDAR = 'CALENDAR',
    NOTIFICATION = 'NOTIFICATION',
    PROFILE = 'PROFILE',
    ORGANIZATIONS = 'ORGANIZATIONS',
    ORGANIZATION = 'ORGANIZATION',
}

export interface MenuItem {
    code: string;
    path: string;
    icon: string;
    label: string;
    position: number;
    options?: any;
    queryParams?: any;
    type: MenuItemType;
    restricted_to: {
        role: string
    }[];
    universalSearchEnabled?: boolean;
    universalSearchGroupType?: string;
}

export enum MenuItemType {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL",
    DIVIDER = "DIVIDER"
}

export interface MenuChangeEvent {
    key: string;
    menuItem: MenuItem;
    routeEvent?: boolean;
}
