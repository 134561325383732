import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UnauthenticatedGuard  {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkAuthentication();
    }

    canActivateChild(
        _childRoute: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkAuthentication();
    }

    private checkAuthentication() {
        const isLogged = !!this.authService.accessToken;
        return !isLogged ? true : this.router.parseUrl('/app/home');
    }

}
