import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MessageService} from 'primeng/api';
import {AuthService} from "../services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private messageService: MessageService,
        private authService: AuthService,
    ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 415) {
                        console.log(`intercept 415 ${request.url}`);
                        this.messageService.add({severity: 'error', detail: 'La richiesta è troppo grande'});
                    }

                    if (err.status === 401 && request.url != '/api/authentication/signin') {
                        console.log(`intercept 401 ${request.url}`);
                        this.messageService.add({severity: 'error', detail: 'Utente non autorizzato'});
                        this.authService.doLogout();
                    }
                    if (err.status === 422) {
                        console.log(`intercept 422 ${request.url}`);
                        this.messageService.add({severity: 'error', detail: 'Operazione non consentita'});
                    }
                }
                return throwError(err);
            })
        );
    }
}
