import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InputTextModule} from 'primeng/inputtext';
import {SidebarModule} from 'primeng/sidebar';
import {BadgeModule} from 'primeng/badge';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import {RippleModule} from 'primeng/ripple';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {RouterModule} from '@angular/router';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppConfigModule} from './config/config.module';
import {AppSidebarComponent} from "./app.sidebar.component";
import {AppLayoutComponent} from "./app.layout.component";
import {SlideMenuModule} from 'primeng/slidemenu';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {ToastModule} from 'primeng/toast';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {VideovisitModule} from '../modules/videovisit/videovisit.module';
import {UiModule} from '../modules/ui/ui.module';
import { AppErrorComponent } from './app.error.component';
import {AvatarModule} from "primeng/avatar";
import { AppTopbarEventsComponent } from './app.topbar.events/app.topbar.events.component';
import {OverlayPanelModule} from "primeng/overlaypanel";
import {EventsModule} from "../modules/events/events.module";
import {PaginatorModule} from "primeng/paginator";

@NgModule({ declarations: [
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppSidebarComponent,
        AppLayoutComponent,
        AppErrorComponent,
        AppTopbarEventsComponent,
    ],
    exports: [AppLayoutComponent], imports: [BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        InputTextModule,
        SidebarModule,
        BadgeModule,
        RadioButtonModule,
        InputSwitchModule,
        RippleModule,
        RouterModule,
        AppConfigModule,
        SlideMenuModule,
        ConfirmDialogModule,
        MessagesModule,
        ToastModule,
        BreadcrumbModule,
        VideovisitModule,
        UiModule,
        AvatarModule,
        OverlayPanelModule,
        EventsModule,
        PaginatorModule], providers: [ConfirmationService, provideHttpClient(withInterceptorsFromDi())] })
export class AppLayoutModule {
}
